import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', loadComponent: () => import('../app/dashbaord/dashboard/dashboard.component').then(c => c.DashboardComponent) },
  { path: 'global-tokenomics', loadComponent: () => import('../app/global-tokenomics/global-tokenomics.component').then(c => c.GlobalTokenomicsComponent) },
  { path: 'distribution-plan', loadComponent: () => import('../app/distribution-plan/distribution-plan.component').then(c => c.DistributionPlanComponent) },
  { path: 'tokenomics', loadComponent: () => import('../app/tokenomics/tokenomics.component').then(c => c.TokenomicsComponent) },
  { path: 'user-details', loadComponent: () => import('../app/user-details/user-details.component'). then(c => c.UserDetailsComponent) },
  { path: 'social-media', loadComponent: () => import('../app/social-media/social-media.component'). then(c =>c.SocialMediaComponent) }
];

