import { RouterOutlet } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { NgxSpinnerModule } from "ngx-spinner";
import { BsModalRef, BsModalService, ModalDirective, ModalModule, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import { LocalStorageService } from './shared/services/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [RouterOutlet, NgIf,CommonModule, NgxSpinnerModule, ModalModule,NgIf, TranslateModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponent implements OnInit {
  title = 'aoc-website';
  private intervalSubscription: Subscription;
  
  constructor(
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
  ){
  }
  ngOnInit(){
    this.selectLanguage('en');
  }

  selectedLanguage: { name: string, icon: string } | null = null;
  /**
   * Selects the given language, stores it in local storage, and updates the translation service.
   * The function also sets up an interval to periodically check the stored language value.
   * Updates the `selectedLanguage` property with the appropriate language data.
   *
   * @param language - The language code to select (e.g., 'fr' for French, 'en' for English).
   */
  selectLanguage(language: string) {
    this.intervalSubscription = interval(1000) // Emit a value every 1 second (1000 milliseconds)
    .subscribe(() => {
      const language = this.localStorageService.getDataByKey('lang');
    });
    this.localStorageService.storeData('lang',language);
    this.translate.setDefaultLang(language);
    const languageOptions = {
      'fr': { name: 'fr', icon: 'french.svg' },
      'en': { name: 'en', icon: 'us.svg' }
    };
    this.selectedLanguage = languageOptions[language];
  }
}
